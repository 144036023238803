// Plugin: Angular Perfect Scrollbar
// github: https://github.com/zefoy/ngx-perfect-scrollbar

.ps__rail-x.ps--clicking .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x:hover > .ps__thumb-x {
  background-color: darken($card-bg, 30%);
  height: 6px;
}

.ps__rail-x {
  height: 10px;
}

.ps__thumb-y {
  background-color: darken($card-bg, 15%);
  width: 4px;
  // RTL fix
  right: 2px !important;
  left: auto !important;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background-color: darken($card-bg, 30%);
  width: 6px;
}

.ps__rail-y {
  width: 10px;
  // RTL fix
  right: 0 !important;
  left: auto !important;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background-color: $light;
}

// Plugin: Ng-select
// github: https://github.com/ng-select/ng-select

.ng-select {
  .ng-select-container {
    @extend .form-control;
    padding: 0 !important;
    min-height: 38px;
    .ng-value {
      color: $primary-5;
    }
    .ng-value-container {
      .ng-placeholder {
        color: $neutral-8;
      }
    }
  }
  .ng-arrow-wrapper {
    width: 35px;
    padding-right: 10px;
    padding-top: 5px;
    .ng-arrow {
      border: none;
      background: url('../../../images/icons/arrow-down.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px 12px;
      height: 18px !important;
      width: 18px !important;
    }
  }
  .ng-clear-wrapper {
    color: $text-muted;
  }
  &.disabled {
    pointer-events: none;
    .ng-select-container {
      background-color:#E8E6E6;
      .ng-value {
        color: #6A6E74;
      }
    }
  }
  &.ng-select-opened {
    .ng-select-container {
      background: $input-focus-bg;
      border-color: $primary;
      border-radius: 4px !important;
      .ng-arrow-wrapper .ng-arrow {
        transform: rotate(180deg);
        top: 1px;
      }
    }
  }
  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: $input-focus-border-color;
  }
  &.ng-select-single {
    .ng-select-container {
      height: 38px;
      .ng-value-container .ng-input {
        top: 7px;
      }
    }
  }
  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          top: 8px;
          color: $neutral-8;
        }
        .ng-value {
          background-color: $primary;
          color: $white;
          .ng-value-icon {
            padding: 5px 7px;
            &:hover {
              background-color: darken($primary, 5%);
            }
            &.left {
              border-right: 1px solid rgba($white, 0.3);
              &:hover {
                border-radius: 2px 0 0 2px;
              }
            }
          }
          .ng-value-lable {
            padding: 6px 7px;
          }
        }
      }
    }
  }
}

.ng-dropdown-panel {
  border: none;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0px 0px 20px 0px #e6e0e9;
  padding: 8px 0;
  margin-top: 2px !important;
  .ng-dropdown-panel-items {
    .ng-option,
    .ng-optgroup {
      background-color: $white;
      color: $primary-5;
      font-weight: 500;
      padding: 6px 12px;
      &.ng-option-marked {
        background-color: $tertiary-9;
        color: $primary-5;
      }
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: rgba($primary, 0.1);
        color: $primary;
      }
      &.ng-option-disabled {
        color: $gray-800;
      }
    }
  }
  input[type='checkbox'] {
    @extend .form-check-input;
    margin-right: 6px;
  }
  &.ng-select-top {
    border-bottom-color: $dropdown-border-color;
  }
  &.ng-select-bottom {
    border-top-color: $dropdown-border-color;
  }
}

/* Buttons */

.btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
  padding: 10.5px 24px;
  border-radius: 100px;
  letter-spacing: 0.1px;
  border: none;
  &.btn-primary {
    background-color: $primary;
    color: $white;
    box-shadow: none;
    &:hover {
      box-shadow:
        0px 1px 3px 1px rgba(0, 0, 0, 0.15),
        0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    }
    &:active,
    &:focus,
    &:focus-within {
      background-color: $primary;
      color: $white;
      box-shadow: none;
    }
    &.btn-disabled {
      background-color: rgba(29, 27, 32, 0.12);
      color: $primary-13;
      cursor: not-allowed;
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.btn-secondary {
    background-color: $secondary-2;
    color: $white;
    &:hover,
    &:focus,
    &:focus-within {
      background-color: $primary;
      color: $white;
    }
  }
  &.btn-outline {
    color: $primary !important;
    border: 1px solid $neutral-14;
    background-color: transparent;
    &:hover {
      background-color: rgba(103, 80, 164, 0.08);
    }
    &:focus,
    &:focus-within {
      border-color: $primary;
      color: $primary;
      background-color: rgba(103, 80, 164, 0.08);
    }
    &:active {
      background-color: rgba(103, 80, 164, 0.12);
    }
    &.btn-disabled {
      background-color: transparent;
      border-color: rgba(29, 27, 32, 0.12);
      color: rgba(29, 27, 32, 0.38);
      cursor: not-allowed;
    }
    &.btn-outline-primary {
      border: 1px solid $primary;
      &:focus,
      &:focus-within {
        border: 1px solid $primary;
        background-color: transparent;
      }
      &:hover {
        background-color: $primary;
        color: $white !important;
        i {
          color: $white;
        }
      }
      &:disabled {
        background-color: $neutral-9;
        border-color: $neutral-9;
        color:$neutral-variant-4 !important;
      }
    }
  }
  &.btn-icon-text {
    height: 40px;
    padding: 10.5px 24px 10.5px 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    i {
      margin-right: 8px;
      font-size: 16px;
    }
  }
  &.btn-text {
    color: $primary;
    background-color: transparent;
    padding: 10.5px 12px;
    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      color: $primary;
      background-color: rgba(103, 80, 164, 0.08);
    }
    &.btn-disabled {
      color: rgba(29, 27, 32, 0.38);
      cursor: not-allowed;
    }
  }
  &.btn-elevated {
    color: $primary;
    background-color: $neutral-11;
    box-shadow:
      0px 1px 3px 1px rgba(0, 0, 0, 0.15),
      0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    &:hover {
      color: $primary;
      background-color: rgba(103, 80, 164, 0.08);
      box-shadow:
        0px 1px 3px 1px rgba(0, 0, 0, 0.15),
        0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    }
    &:focus,
    &:focus-within,
    &:active {
      color: $primary;
      background-color: rgba(103, 80, 164, 0.12);
      box-shadow:
        0px 1px 3px 1px rgba(0, 0, 0, 0.15),
        0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    }
    &.btn-disabled {
      box-shadow: none;
      background-color: rgba(103, 80, 164, 0.12);
      color: rgba(29, 27, 32, 0.38);
      cursor: not-allowed;
    }
  }
  &.btn-tonal {
    color: $secondary-1;
    background-color: $secondary-11;
    &:hover {
      color: $secondary-1;
      background-color: rgba(29, 25, 43, 0.08);
      box-shadow:
        0px 1px 3px 1px rgba(0, 0, 0, 0.15),
        0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    }
    &:focus,
    &:focus-within,
    &:active {
      color: $secondary-1;
      background-color: rgba(29, 25, 43, 0.12);
      box-shadow: none;
    }
    &.btn-disabled {
      box-shadow: none;
      background-color: rgba(29, 27, 32, 0.12);
      color: rgba(29, 27, 32, 0.38);
      cursor: not-allowed;
    }
  }
  &.btn-primary-dark {
    color: $dark-primary;
    background-color: $neutral-variant-13;
    &:hover {
      color: $dark-primary;
      background-color: $neutral-variant-13;
      box-shadow:
        0px 1px 3px 1px rgba(0, 0, 0, 0.15),
        0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    }
    &:active,
    &:focus,
    &:focus-within {
      color: $dark-primary;
      background-color: $neutral-variant-13;
      box-shadow: none;
    }
    &.btn-disabled {
      background-color: rgba(230, 224, 233, 0.12);
      color: rgba(230, 224, 233, 0.38);
      cursor: not-allowed;
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.btn-outline-dark {
    color: $neutral-variant-13;
    border: 1px solid $neutral-15;
    background-color: transparent;
    &:hover {
      color: $neutral-variant-13;
      background-color: rgba(103, 80, 164, 0.08);
    }
    &:focus,
    &:focus-within {
      border-color: $neutral-variant-13;
      color: $neutral-variant-13;
      background-color: rgba(103, 80, 164, 0.08);
    }
    &:active {
      background-color: rgba(103, 80, 164, 0.12);
    }
    &.btn-disabled {
      background-color: transparent;
      border-color: rgba(230, 224, 233, 0.12);
      color: rgba(230, 224, 233, 0.38);
      cursor: not-allowed;
    }
  }
  &.btn-outline-danger {
    color: $danger;
    border: 1px solid $danger;
    background-color: transparent;
    &:hover {
      color: $danger;
      background-color: #f9dedc;
    }
    &:focus,
    &:focus-within {
      border-color: $danger;
      color: $danger;
      background-color: #f9dedc;
    }
    &:active {
      background-color: #f9dedc;
    }
    &.active {
      background-color: #f9dedc;
    }
    &.btn-disabled {
      background-color: transparent;
      border-color: rgba(230, 224, 233, 0.12);
      color: rgba(230, 224, 233, 0.38);
      cursor: not-allowed;
    }
  }
  &.btn-outline-warning {
    color: $warning;
    border: 1px solid $warning;
    background-color: transparent;
    &:hover {
      color: $warning;
      background-color: #fbe2dc;
    }
    &:focus,
    &:focus-within {
      border-color: $warning;
      color: $warning;
      background-color: #fbe2dc;
    }
    &:active {
      background-color: #fbe2dc;
    }
    &.active {
      background-color: #fbe2dc;
    }
    &.btn-disabled {
      background-color: transparent;
      border-color: rgba(230, 224, 233, 0.12);
      color: rgba(230, 224, 233, 0.38);
      cursor: not-allowed;
    }
  }
  &.btn-outline-success {
    color: $success !important;
    border: 1px solid $success;
    background-color: transparent;
    &:hover {
      color: $success;
      background-color: #eff2f1;
    }
    &:focus,
    &:focus-within {
      border-color: $success;
      color: $success;
      background-color: #eff2f1;
    }
    &:active {
      background-color: #eff2f1;
    }
    &.active {
      background-color: #eff2f1;
    }
    &.btn-disabled {
      background-color: transparent;
      border-color: rgba(230, 224, 233, 0.12);
      color: rgba(230, 224, 233, 0.38);
      cursor: not-allowed;
    }
  }
  &.btn-text-dark {
    color: $neutral-variant-13;
    background-color: transparent;
    padding: 10.5px 12px;
    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      color: $neutral-variant-13;
      background-color: rgba(208, 188, 255, 0.08);
    }
    &.btn-disabled {
      background-color: transparent;
      color: rgba(230, 224, 233, 0.38);
      cursor: not-allowed;
    }
  }
  &.btn-elevated-dark {
    color: $neutral-variant-13;
    background-color: $primary-13;
    box-shadow:
      0px 1px 2px 0px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    &:hover {
      color: $neutral-variant-13;
      background-color: rgba(208, 188, 255, 0.08);
    }
    &:focus,
    &:focus-within,
    &:active {
      color: $neutral-variant-13;
      background-color: rgba(103, 80, 164, 0.12);
    }
    &.btn-disabled {
      box-shadow: none;
      background-color: rgba(230, 224, 233, 0.12);
      color: rgba(230, 224, 233, 0.38);
      cursor: not-allowed;
    }
  }
  &.btn-tonal-dark {
    color: $neutral-variant-15;
    background-color: $primary-14;
    &:hover {
      color: $neutral-variant-15;
      background-color: rgba(232, 222, 248, 0.08);
      box-shadow: none;
    }
    &:focus,
    &:focus-within,
    &:active {
      color: $neutral-variant-15;
      background-color: rgba(232, 222, 248, 0.12);
      box-shadow: none;
    }
    &.btn-disabled {
      box-shadow: none;
      background-color: rgba(230, 224, 233, 0.12);
      color: rgba(230, 224, 233, 0.38);
      cursor: not-allowed;
    }
  }
  &.btn-rounded {
    @include border-radius(50px);
  }
  &.btn-lg {
    padding: 18px 30px;
  }
  &:disabled {
    background-color: $neutral-9;
    color: $neutral-variant-4;
    opacity: 1;
  }
  &.btn-xs {
    padding: $btn-padding-y-xs $btn-padding-x-xs;
    font-size: $btn-font-size-xs;
  }
  &.btn-200 {
    min-width: 200px;
  }
  &.btn-180 {
    min-width: 180px;
  }
  &.btn-140 {
    min-width: 140px;
  }
  &.btn-120 {
    min-width: 120px;
  }
  &.btn-104 {
    min-width: 104px;
    padding: 10.5px 15px;
  }
  &.btn-icon {
    height: 38px;
    width: 38px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 1.125rem;
    }
    svg {
      height: 18px;
      width: 18px;
    }
    &.btn-xs {
      width: 30px;
      height: 30px;
      i {
        font-size: 0.875rem;
      }
      svg {
        height: 14px;
      }
    }
    &.btn-sm {
      width: 36px;
      height: 36px;
      i {
        font-size: 1rem;
      }
      svg {
        height: 16px;
        width: 16px;
      }
    }
    &.btn-lg {
      width: 42px;
      height: 42px;
      i {
        font-size: 1.25rem;
      }
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  // Button with icon and text
  // &.btn-icon-text {
  //   height: 38px;
  //   display: inline-flex;
  //   align-items: center;
  //   justify-content: center;
  //   .btn-icon-prepend {
  //     margin-right: .5rem;
  //   }
  //   .btn-icon-append {
  //     margin-left: .5rem;
  //   }
  //   i {
  //     font-size: 1.125rem;
  //   }
  //   svg {
  //     width: 18px;
  //     height: 18px;
  //   }
  //   &.btn-xs {
  //     height: 30px;
  //     i {
  //       font-size: .875rem;
  //     }
  //     svg {
  //       width: 14px;
  //       height: 14px;
  //     }
  //   }
  //   &.btn-sm {
  //     height: 36px;
  //     i {
  //       font-size: 1rem;
  //     }
  //     svg {
  //       width: 16px;
  //       height: 16px;
  //     }
  //   }
  //   &.btn-lg {
  //     height: 42px;
  //     i {
  //       font-size: 1.25rem;
  //     }
  //     svg {
  //       width: 20px;
  //       height: 20px;
  //     }
  //   }
  // }
}
.btn-dropdown {
  border-radius: 100px;
  background-color: transparent;
  padding: 8px 18px;
  border: 1px solid $primary;
  color: $primary;
  font-weight: 500;
  &.btn-140 {
    width: 140px;
  }
  &.btn-180 {
    width: 160px;
  }
  &.btn-primary {
    background-color: $primary;
    color: $white;
    box-shadow: none;
    &:active,
    &:focus,
    &:focus-within {
      background-color: $primary;
      color: $white;
    }
  }
}
.filter-dropdown-btn {
  .dropdown-menu {
    margin-top: 2px !important;
  }
}
// Social buttons
// @each $color, $value in $social-colors {
//   .btn-#{$color} {
//     @include social-button(social-color($color));
//   }
//   .btn-outline-#{$color} {
//     @include social-outline-button(social-color($color));
//   }
// }

// Inverse buttons
@each $color, $value in $theme-colors {
  .btn-inverse-#{$color} {
    @include button-inverse-variant($value);
  }
}

//Link

.btn-link {
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  color: $primary-5;
  &:hover {
    color: $primary-5;
  }
}
.btn-transparent {
  background-color: transparent;
  border: none;
  padding: 0;
}
.dark-theme {
  background-color: #1d1b20;
  padding: 40px;
}
.drag-btn {
  border: 1px solid #d1cccd;
  color: $neutral-variant-4;
  border-radius: 4px;
  height: 38px;
  width: 68px;
  padding: 2px;
  &:hover,
  &:focus,
  &:focus-within {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
}

@charset "UTF-8";

@font-face {
  font-family: "sid-cms";
  src:url("fonts/sid.eot");
  src:url("fonts/sid.eot?#iefix") format("embedded-opentype"),
    url("fonts/sid.woff") format("woff"),
    url("fonts/sid.ttf") format("truetype"),
    url("fonts/sid.svg#sid-cms") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "sid-cms" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "sid-cms" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cross:before {
  content: "\e900";
  color: #e80000;
}

.icon-category:before {
  content: "\61";
}
.icon-close-circle:before {
  content: "\62";
}
.icon-document-download:before {
  content: "\63";
}
.icon-import:before {
  content: "\64";
}
.icon-minus-cirlce:before {
  content: "\65";
}
.icon-undefined:before {
  content: "\65";
}
.icon-tick-circle:before {
  content: "\66";
}
.icon-trash:before {
  content: "\67";
}
.icon-trash-filled:before {
  content: "\68";
}
.icon-add-square:before {
  content: "\69";
}
.icon-filter-search:before {
  content: "\6a";
}
.icon-search:before {
  content: "\6b";
}
.icon-setting:before {
  content: "\6c";
}
.icon-arrow-down:before {
  content: "\6d";
}
.icon-arrow-up:before {
  content: "\6e";
}
.icon-arrow-right:before {
  content: "\6f";
}
.icon-arrow-left:before {
  content: "\70";
}
.icon-angle-double-right:before {
  content: "\71";
}
.icon-angle-double-left:before {
  content: "\72";
}
.icon-angle-left:before {
  content: "\73";
}
.icon-angle-right:before {
  content: "\74";
}
.icon-angle-down:before {
  content: "\75";
}
.icon-angle-up:before {
  content: "\76";
}
.icon-bars:before {
  content: "\77";
}
.icon-info-circle:before {
  content: "\78";
}
.icon-info-warning:before {
  content: "\79";
}
.icon-upload:before {
  content: "\7a";
}
.icon-download:before {
  content: "\41";
}
.icon-bus-rest:before {
  content: "\42";
}
.icon-bus-trip:before {
  content: "\43";
}
.icon-edit-pencil:before {
  content: "\44";
}
.icon-garage:before {
  content: "\45";
}
.icon-sort:before {
  content: "\46";
}
.icon-plus:before {
  content: "\47";
}
.icon-map:before {
  content: "\48";
}
.icon-drag-list:before {
  content: "\49";
}
.icon-dots:before {
  content: "\4a";
}
.icon-edit-pencil-line:before {
  content: "\4b";
}
.icon-copy:before {
  content: "\4c";
}
.icon-export:before {
  content: "\4d";
}
.icon-spinner:before {
  content: "\4e";
}
.icon-warning-2:before {
  content: "\4f";
}
.icon-add-circle:before {
  content: "\50";
}
.icon-lang:before {
  content: "\51";
}
.icon-profile-add:before {
  content: "\52";
}
.icon-lamp-charge:before {
  content: "\53";
}
.icon-user:before {
  content: "\54";
}
.icon-document-text:before {
  content: "\55";
}
.icon-calendar:before {
  content: "\56";
}
.icon-card-tick:before {
  content: "\57";
}
.icon-cards:before {
  content: "\58";
}
.icon-time-circle:before {
  content: "\59";
}
.icon-routing:before {
  content: "\5a";
}
.icon-work:before {
  content: "\30";
}
.icon-notification:before {
  content: "\31";
}
.icon-ticket:before {
  content: "\32";
}
.icon-profile-delete:before {
  content: "\33";
}
.icon-top-up:before {
  content: "\34";
}
.icon-bus:before {
  content: "\35";
}
.icon-warning-sign:before {
  content: "\36";
}
.icon-icon:before {
  content: "\37";
}
.icon-top-up-outlined:before {
  content: "\38";
}
.icon-special-pass:before {
  content: "\39";
}
.icon-timebased-pass:before {
  content: "\21";
}
.icon-free-pass:before {
  content: "\22";
}
.icon-tap-out:before {
  content: "\23";
}
.icon-blocked:before {
  content: "\24";
}
.icon-tap-in:before {
  content: "\25";
}
.icon-unblocked:before {
  content: "\26";
}
.icon-information-circular-button-svgrepo-com:before {
  content: "\27";
}
.icon-warning-circle:before {
  content: "\28";
}
.icon-minus-square:before {
  content: "\29";
}
.icon-clock-cirlce:before {
  content: "\2a";
}
.icon-clock-time:before {
  content: "\2b";
}
.icon-info-circle-outline:before {
  content: "\2c";
}
.icon-map-marker:before {
  content: "\2d";
}
.icon-stats:before {
  content: "\2e";
}
.icon-tick-circle-filled:before {
  content: "\2f";
}
.icon-close-circle-filled:before {
  content: "\3a";
}

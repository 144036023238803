/* You can add global styles to this file, and also import other style files */

// Global icon font
@import './assets/fonts/feather/style.css';
@import './assets/fonts/iconsax/style';
@import './assets/fonts/custom-fonts/styles.css';

// // 3rd party plugins
@import '@mdi/font/css/materialdesignicons.min.css';
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import 'highlight.js/styles/googlecode.css';
@import 'animate.css/animate.min.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'metismenujs/scss/metismenujs';
@import 'dropzone/dist/min/dropzone.min.css';
@import 'quill/dist/quill.snow.css';
// @import "angular-archwizard/styles/archwizard.css";
@import 'simple-datatables/dist/style.css';
@import 'node_modules/@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/themes/bootstrap.css';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';

// SID  styles for LTR
@import './assets/scss/style';

// SID  styles For RTL
// @import "./assets/scss/style.rtl.css";

// for material theme
@import "node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

@import "https://fonts.googleapis.com/icon?family=Material+Icons";
// @import "./theme/theme.scss";



.mat-button, .mat-flat-button, .mat-raised-button {
    text-transform: uppercase;
  }
  
  
  
  .country-list-button {
    .flag {
      filter: none !important;
    }
  }
  
  input.country-search {
    margin-top: -7px !important;
  }
  
  
  .mat-select, .mat-option {
    font-family: Roboto, "Helvetica Neue", 'Noto Color Emoji', sans-serif;
  }
  
  
  @-moz-document url-prefix() {
    .mat-select, .mat-option {
      font-family: Roboto, "Helvetica Neue", sans-serif;
    }
  }
  
  .mat-form-field-outline ~ .mat-form-field-prefix {
    top: 0 !important;
  }
  
  .empty-placeholder {
    display: block;
    font-size: 24px;
    padding-top: 36px;
    padding-bottom: 36px;
    width: 100%;
    text-align: center;
    color: #7f7f7f;
  }
  
  .mat-form-field.fill-container,
  .mat-form-field.fill-container .mat-form-field-infix,
  .mat-form-field.fill-container .mat-form-field-flex,
  .mat-form-field.fill-container .mat-form-field-wrapper {
    height: 100%;
    width: 100%;
  }
  
  .mat-form-field.fill-container textarea {
    height: calc(100% - 25px);
  }
  
  .mdc-list-item__primary-text{
    display: flex;
  }
  .mdc-list-item__primary-text .mat-icon{
    margin-right: 5px;
  }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  -webkit-text-fill-color : #000000 !important;
}
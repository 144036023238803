// Forms
.form-field-group {
  background-color: $white;
  border-radius: 8px;
  padding: 6px 16px;
  box-shadow: 0px 0px 20px 0px rgba(230, 224, 233, 0.45);
  height: auto;
  label {
    font-size: 12px;
    font-weight: 400;
    color: $neutral-13;
  }
  .form-control {
    padding: 0;
    border: none;
    font-size: 16px;
    color: $primary-5;
    &::placeholder {
      color: $neutral-5;
    }
    &::-moz-placeholder {
      color: $neutral-5;
    }
  }
  &.password-field {
    button {
      top: 14px;
    }
  }
}
.form-field {
  label {
    margin-bottom: 6px;
    display: inline-block;
    font-size: 14px;
    color: $primary-5;
    position: relative;
    letter-spacing: 0.25px;
    &.required {
      margin-right: 7px;
      &::after {
        content: "*";
        color: $primary;
        position: absolute;
        right: -9px;
        top: 0;
      }
    }
  }
  .form-control {
    height: 38px;
    border-radius: 4px;
    background-color: $white;
    border: 1px solid $neutral-8;
    color: $primary-5;
    padding: 8px 12px;
    &::placeholder {
      color: $neutral-8;
    }
    &::-moz-placeholder {
      color: $neutral-8;
    }
    &::-webkit-input-placeholder {
      color: $neutral-8;
    }
    &:-ms-input-placeholder {
      color: $neutral-8;
    }
    &:-moz-placeholder {
      color: $neutral-8;
    }
    &.disabled {
      background-color: $neutral-9 !important;
      pointer-events: none !important;
      color: $neutral-variant-4;
      &::placeholder {
        color: $neutral-variant-4;
      }
      &::-moz-placeholder {
        color: $neutral-variant-4;
      }
      &::-webkit-input-placeholder {
        color: $neutral-variant-4;
      }
      &:-ms-input-placeholder {
        color: $neutral-variant-4;
      }
      &:-moz-placeholder {
        color: $neutral-variant-4;
      }
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px $neutral-9 inset;
      -webkit-text-fill-color: $neutral-variant-4;
    }
    &.field-small {
      width: 68px;
      text-align: center;
    }
    &.field-160 {
      width: 160px;
    }
  }
  &.field-110 {
    width: 110px;
  }
  &.field-70 {
    width: 70px;
  }
  &.field-160.time-field input[type="text"] {
    background-position: 93% center;
  }
  &.icon-field-append {
    position: relative;
    .field-icon {
      position: absolute;
      top: 34px;
      right: 14px;
      font-size: 20px;
    }
  }
}
.text-field-append {
  position: relative;
  span {
    position: absolute;
    top: 9px;
    right: 14px;
    font-size: 14px;
  }
  .form-control {
    padding: 8px 40px 8px 12px;
  }
}
.text-field-append-forms {
  position: relative;
  span {
    position: absolute;
    top: 36px;
    right: 14px;
    font-size: 14px;
  }
  .form-control {
    padding: 8px 40px 8px 12px;
  }
}
.password-field {
  position: relative;
  button {
    position: absolute;
    top: 35px;
    right: 16px;
    background-color: transparent;
    padding: 0;
    border: none;
    font-size: 20px;
  }
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url(../../images/icons/calendar.svg) no-repeat;
  width: 16px;
  height: 16px;
}
.time-field input[type="text"] {
  background: url(../../images/icons/clock.svg) no-repeat;
  background-size: 18px;
  background-position: 98% center;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
.form-control-xs,
.form-select-xs {
  padding: $input-padding-y-xs $input-padding-x-xs;
  font-size: $input-font-size-xs;
}

.form-control-xs {
  + .input-group-text {
    padding-top: $input-padding-y-xs;
    padding-bottom: $input-padding-y-xs;
  }
}

.form-check-input {
  margin-top: 0.13em; // height adjustment
}

.input-group-text {
  svg {
    width: 18px;
    height: 18px;
  }
}

.input-group {
  // To fix Bootstrap-datepicker in RTL
  direction: ltr;
}

// For RTL
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
select {
  background: url("../../images/icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: 89% 12px;
}
textarea {
  &.form-control {
    min-height: 100px;
    resize: none;
  }
}
// input[type="date"]::-webkit-datetime-edit-text,
// input[type="date"]::-webkit-datetime-edit-month-field,
// input[type="date"]::-webkit-datetime-edit-day-field,
// input[type="date"]::-webkit-datetime-edit-year-field {
//   color: $neutral-8;
// }

input[type="date"].form-control.disabled,
.time-field .form-control.disabled {
  color: $neutral-8;
}

// Dropdowns

.dropdown,
.btn-group {
  .dropdown-toggle {
    &.no-dropdown-toggle-icon {
      &::after {
        display: none;
      }
      &:active {
        border-color: transparent;
      }
    }
    &:after {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      font: normal normal normal 24px/1 'sid-cms';
      content: '\6d';
      width: auto;
      height: auto;
      vertical-align: middle;
      line-height: 0.625rem;
      font-size: 0.875rem;
    }
    &.show {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
  &.errors-dropdown {
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }
  &.dropup {
    .dropdown-toggle {
      &::after {
        content: '\6d';
      }
    }
  }
  &.dropstart,
  &[placement='start-top'],
  &[placement='start-bottom'] {
    .dropdown-toggle {
      &::after {
        content: '';
      }
      &::before {
        border: 0;
        font: normal normal normal 24px/1 'sid-cms';
        content: '\6d';
        width: auto;
        height: 0.875rem;
        display: inline-block;
        vertical-align: middle;
        line-height: 0.625rem;
        font-size: 0.875rem;
      }
      &:empty::before {
        margin-right: 0;
      }
    }
  }
  &.dropend,
  &[placement='end-top'],
  &[placement='end-bottom'] {
    .dropdown-toggle {
      &::after {
        content: '\6d';
      }
    }
  }
}

.dropdown-menu {
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px #e6e0e9;
  margin-top:2px !important;
  .dropdown-item {
    padding: 4px 12px;
  }
}

.dropdown-item {
  font-size: 0.812rem;
  padding: 4px 12px 4px 20px;
  font-weight: 500;
  i,
  svg {
    color: $primary-1;
    margin-right: 2px;
    font-size: 14px;
  }
  &:not(&:active, &.active):hover {
    background-color: $tertiary-9;
    &,
    i,
    svg {
      color: $primary-1;
    }
  }
  &:active,
  &.active {
    i,
    svg {
      color: $white;
    }
  }
}
